// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-faq-[company]-js": () => import("./../../../src/pages/faq/[company].js" /* webpackChunkName: "component---src-pages-faq-[company]-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-home-components-footer-js": () => import("./../../../src/pages/home/components/footer.js" /* webpackChunkName: "component---src-pages-home-components-footer-js" */),
  "component---src-pages-home-components-header-js": () => import("./../../../src/pages/home/components/header.js" /* webpackChunkName: "component---src-pages-home-components-header-js" */),
  "component---src-pages-home-components-intro-js": () => import("./../../../src/pages/home/components/intro.js" /* webpackChunkName: "component---src-pages-home-components-intro-js" */),
  "component---src-pages-home-components-section-2-js": () => import("./../../../src/pages/home/components/section2.js" /* webpackChunkName: "component---src-pages-home-components-section-2-js" */),
  "component---src-pages-home-components-section-3-js": () => import("./../../../src/pages/home/components/section3.js" /* webpackChunkName: "component---src-pages-home-components-section-3-js" */),
  "component---src-pages-home-components-section-4-js": () => import("./../../../src/pages/home/components/section4.js" /* webpackChunkName: "component---src-pages-home-components-section-4-js" */),
  "component---src-pages-home-components-section-5-js": () => import("./../../../src/pages/home/components/section5.js" /* webpackChunkName: "component---src-pages-home-components-section-5-js" */),
  "component---src-pages-home-components-section-6-js": () => import("./../../../src/pages/home/components/section6.js" /* webpackChunkName: "component---src-pages-home-components-section-6-js" */),
  "component---src-pages-home-components-section-7-js": () => import("./../../../src/pages/home/components/section7.js" /* webpackChunkName: "component---src-pages-home-components-section-7-js" */),
  "component---src-pages-home-components-section-8-js": () => import("./../../../src/pages/home/components/section8.js" /* webpackChunkName: "component---src-pages-home-components-section-8-js" */),
  "component---src-pages-home-components-section-9-js": () => import("./../../../src/pages/home/components/section9.js" /* webpackChunkName: "component---src-pages-home-components-section-9-js" */),
  "component---src-pages-home-home-screen-js": () => import("./../../../src/pages/home/home-screen.js" /* webpackChunkName: "component---src-pages-home-home-screen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

